<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"></el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
            <div>
				<el-select class="w110" size="small" v-model="search.group_status" clearable @change="searchList()">
					<template v-for="(value,key) in group_status">
						<el-option :key="key" :label="value" :value="key"></el-option>
					</template>
				</el-select>
                <!-- <el-select class="w110" size="small" v-model="search.pay_status" clearable @change="searchList()">
					<template v-for="(value,key) in pay_status">
						<el-option :key="key" :label="value" :value="key"></el-option>
					</template>
				</el-select> -->
            </div>
		</div>
		<el-table 
			:data="list" 
			:max-height="tableHeight" 
			:header-cell-style="{background:'#f5f7fa'}"
			style="width: 100%">
            <el-table-column prop="order_id" label="订单id"></el-table-column>
			<el-table-column prop="group_no" label="团号"></el-table-column>
            <el-table-column prop="member_id" label="团长id"></el-table-column>
			<el-table-column prop="member_name" label="团长昵称"></el-table-column>
            <el-table-column prop="course_name" label="购买课程"></el-table-column>
			<el-table-column prop="org_name" label="机构"></el-table-column>
			<el-table-column prop="ShortName" label="机构城市"></el-table-column>
            <el-table-column prop="group_status" label="团状态">
				<template slot-scope="scope">
					{{ group_status[scope.row.group_status] }}
				</template>
			</el-table-column>
            <el-table-column prop="pay_status" label="支付状态">
				<template slot-scope="scope">
					{{ pay_status[scope.row.pay_status] }}
				</template>
			</el-table-column>
            <el-table-column prop="current_group_people_num" label="现有团人数"></el-table-column>
			<el-table-column label="操作" min-width="80">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.group_no)">详情</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
		<GroupDetail ref="detail" :dialogVisible='dialogVisible' @close="close"></GroupDetail>
	</div>
</template>
<script>
	import { groupWorkList } from '@/api/coupon.js'
	import GroupDetail from './component/groupDetail.vue'
	export default {
		name: "groupTracking",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
                group_status: { 1: '开启', 2: '关闭', 3: '完成' },
                pay_status: { 0: '未支付', 1: '已支付', 2: '已退款'},
				//搜索依据
				searchGist: [{
					value: 'member_name',
					label: '团长昵称'
				}, {
					value: 'group_no',
					label: '团号'
				}, {
					value: 'org_name',
					label: '机构名称'
				}, {
					value: 'course_name',
					label: '课程名称'
				}],
				// 搜索条件
				search: {
					search_gist: 'member_name',
					desp: '',
					group_status: '',
					pay_status: '',
				},
				total: 0,
				limit: 15,
				currentPage: 1,
				list: [],
				// 弹框
				dialogVisible: false,
				id: 0,
			}
		},
		components: {
			GroupDetail
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
					this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				groupWorkList({
					limit: this.limit,
					page: this.currentPage,
					group_status: this.search.group_status || null,
					// pay_status: this.search.pay_status || null,
					member_name: this.search.search_gist == 'member_name'?this.search.desp:null, //昵称搜索
					group_no:  this.search.search_gist == 'group_no'?this.search.desp:null, //电话搜索
					org_name:  this.search.search_gist == 'org_name'?this.search.desp:null, //订单号搜索
					course_name: this.search.search_gist == 'course_name'?this.search.desp:null,//机构名搜索
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err)
				})
			},
			// 搜索
			searchList() {
				this.loading = true
				this.currentPage = 1;
				this.getList();
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
			// 订单详情
			toDetail(id) {
                console.log(this.$refs.detail)
				this.$refs.detail.getList(id)
				this.dialogVisible = true
			},
			// 关闭弹框
			close() {
				this.dialogVisible = false
			},
		}
	};
</script>
