<template>
	<el-dialog title="详情" :visible.sync="dialogVisible" width="900px" :before-close="handleClose">
		<el-table v-loading="loading"
			:data="list" 
			max-height="700px" 
			:header-cell-style="{background:'#f5f7fa'}"
			style="width: 100%">
            <el-table-column prop="member_name" label="团员名称"></el-table-column>
            <el-table-column prop="member_name" label="是否团长">
                <template slot-scope="scope">
                    {{ scope.row.group_is_head == 1 ? '是' : '否' }}
                </template>
            </el-table-column>
			<el-table-column prop="order_id" label="订单id"></el-table-column>
            <el-table-column prop="order_time" label="下单时间">
				<template slot-scope="scope">
                    {{ scope.row.order_time*1000 | formatDate }}
                </template>
			</el-table-column>
            <el-table-column prop="pay_time" label="支付时间">
				<template slot-scope="scope">
                    {{ scope.row.pay_time*1000 | formatDate }}
                </template>
			</el-table-column>
            <el-table-column prop="group_status" label="团状态">
				<template slot-scope="scope">
					{{ group_status[scope.row.group_status] }}
				</template>
			</el-table-column>
            <el-table-column prop="pay_status" label="支付状态">
				<template slot-scope="scope">
					{{ pay_status[scope.row.pay_status] }}
				</template>
			</el-table-column>
		</el-table>
	</el-dialog>
</template>
<script>
	import { groupWorkDetail } from '@/api/coupon.js'
	export default {
        name: "GroupDetail",
        props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
                loading: true,
                group_status: { 1: '开启', 2: '关闭', 3: '完成' },
                pay_status: { 0: '未支付', 1: '已支付', 2: '已退款'},
				list: [],
			}
		},
		methods: {
			// 获取列表
			getList(id) {
                this.loading = true
				groupWorkDetail({
					group_no: id
				}).then((res) => {
                    console.log(res, 111)
					this.list = res.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err)
				})
            },
            handleClose() {
                this.$emit('close');
            }
		}
	};
</script>
